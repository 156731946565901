<template>
    <section class="content">
        <div class="box d-flex flex-column">
            <div class="d-flex justify-content-end align-items-center mb-2">
                <div class="input-group input-group-sm">
                    <label class="text-right col-lg-8">Eventos</label>
                    <select
                        type="text"
                        class="select-event col-lg-12 form-control" 
                        v-model="request.event"
                        required
                    >
                        <option v-for="(event, i) in events" 
                            :key="i" :value="event.value">
                            {{event.label}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="input-group input-group-sm">
                <input
                    v-model="request.url"
                    type="text"
                    :class="`${this.fieldsError.url ? 'is-invalid' : this.request.url && 'is-valid'} form-control col-lg-10`"
                    placeholder="URL da requisiçao"
                    @blur="validateFields"
                    required
                />
                <select
                    type="text"
                    class="col-lg-2 form-control"
                    v-model="request.method"
                    required
                >
                    <option v-for="(method, i) in methods" 
                        :key="i" :value="method.value">
                        {{method.label}}
                    </option>
                </select>
            </div>

            <div class="mt-2">
                <div class="d-flex mb-1">
                    <button 
                        :class="`form-control col-lg-2 btn btn-sm mr-1 ${showBodyPart ? 'btn-outline-secondary' : 'btn-secondary'}`"
                        @click="showBodyPart = false"
                    >
                        <b>Headers</b>
                    </button>
                    <button 
                        :class="`form-control col-lg-2 btn btn-sm ${showBodyPart ? 'btn-secondary' : 'btn-outline-secondary'}`"
                        @click="showBodyPart = true"
                    >
                        <b>Body</b>
                    </button>
                </div>

                <div class="box-content bg-secondary">
                    <div v-if="showBodyPart" class="d-flex justify-content-start align-items-center pt-1">
                        <label for="json" class="control-label checkbox-inline ml-2">
                            <input type="radio" value="json" id="json" v-model="request.body_type"/> 
                            <span class="ml-1">JSON</span>
                        </label>
                        <label for="form-data" class="control-label checkbox-inline ml-2 mr-2">
                            <input type="radio" value="form-data" id="form-data" v-model="request.body_type"/> 
                            <span class="ml-1">Form-Data</span>
                        </label>
                    </div>

                    <ContentRequestForm
                        :request="request"
                        :showBodyPart="showBodyPart"
                    />
                </div>

                <div class="d-flex justify-content-end mt-2">
                    <button
                        v-if="request._id"
                        class="form-control col-lg-2 btn btn-secondary btn-sm mr-2"
                        @click="$emit('clear-webhook')"
                    >
                        <b>Voltar</b>
                    </button>
                    <button 
                        class="form-control col-lg-2 btn btn-primary btn-sm"
                        @click="sendRequest"
                    >
                        <span v-if="spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <b v-else>{{request._id ? 'Atualizar' : 'Criar'}}</b>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { WebhooksService } from "../../../../services/webhooks.service"
export default {
    methods: {
        validateFields() {
            this.fieldsError = {
                url: !WebhooksService.validateUrl(this.request.url)
            }

            const {url} = this.fieldsError;

            return url;
        },
        sendRequest() {
            if (this.validateFields()) return false;

            this.$emit(`${this.request._id ? 'update-webhook' : 'create-webhook'}`);
        }
    },
    components: {
        ContentRequestForm: () => import("./ContentRequestForm.vue"),
    },
    props: {
        request: {
            _id: String,
            event: String,
            url: String,
            method: String,
            headers: Array,
            body: Array,
            body_type: String
        },
        spinner: Boolean
    },
    data () {
        return {
            events: [
                {
                    value: 'create_company',
                    label: 'Criação de empresa'
                },
                {
                    value: 'create_employee',
                    label: 'Criação de funcionário'
                }
            ],
            methods: [
                {
                    value: 'GET',
                    label: 'GET'
                },
                {
                    value: 'POST',
                    label: 'POST'
                },
                {
                    value: 'PUT',
                    label: 'PUT'
                },
                {
                    value: 'PATCH',
                    label: 'PATCH'
                },
                {
                    value: 'DELETE',
                    label: 'DELETE'
                },
            ],
            showBodyPart: false,
            fieldsError: {
                url: false
            }
        }
    },
}
</script>

<style>
    .content {
        flex: 1;
    }

    .select-event {
        border-radius: 12px;
    }

    .box {
        flex: 1;
        min-height: 100%;
        padding: 10px;
        margin: 0px 5px;
        border-radius: 8px;
        background-color: rgb(201, 201, 201);
    }

    .select-method {
        width: 50px;
    }

    .box-content {
        border-radius: 3px;
    }

    .table-modal {
        display: block; 
        max-height: 38vh; 
        overflow-y: scroll;
    }
</style>